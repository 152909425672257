import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import Label from '@/views/Label.vue';
import Loading from '@/views/Loading.vue';
import Order from '@/views/Order.vue';
import TicketCreate from '@/views/TicketCreate.vue';
import Onboard from '@/views/Onboard.vue';
import { authGuard } from './auth_guard';
import { CONFIG_APP_IS_DOWN } from '@/config';

Vue.use(VueRouter);

const routes = [
  { path: '/down', name: 'down', component: () => import('@/views/Down.vue') },
  { path: '/', redirect: '/dashboard' },
  {
    path: '/dashboard',
    name: 'home',
    component: Home,
    beforeEnter: authGuard,
  },
  {
    path: '/dashboard/:activeTabName(orders|problematic|labels|tracking|delivered|delayed|return|other)',
    name: 'dashboard',
    component: Home,
    props: true,
    beforeEnter: authGuard,
  },
  {
    path: '/dashboard/orders/create',
    name: 'order_create', // haven't found a way to do this with params without clashing with the orders tab
    component: Order,
    beforeEnter: authGuard,
  },
  {
    path: '/dashboard/orders/:orderId?',
    name: 'order',
    component: Order,
    beforeEnter: authGuard,
  },
  {
    path: '/dashboard/labels/:labelId?',
    name: 'label',
    component: Label,
    beforeEnter: authGuard,
  },
  {
    path: '/dashboard/labels/:labelId?/create-ticket',
    name: 'create_ticket',
    component: TicketCreate,
    beforeEnter: authGuard,
  },
  { path: '/dashboard/*', redirect: '/dashboard' },
  {
    path: '/settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/Settings.vue'),
    beforeEnter: authGuard,
    children: [
      {
        path: 'account',
        name: 'account',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/Account.vue'),
        children: [
          { path: 'collection', name: 'collection', component: () => import('@/views/settings/Collection.vue') },
        ],
      },
      {
        path: 'branding',
        name: 'branding',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/Branding.vue'),
      },
      {
        path: 'printer',
        name: 'printer',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/Printer.vue'),
      },
      {
        path: 'return',
        name: 'return',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/Return.vue'),
      },
      {
        path: 'tracking',
        name: 'tracking',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/Tracking.vue'),
      },
      {
        path: 'tracking/email/:email',
        name: 'tracking-email',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/TrackingEmail.vue'),
      },
      {
        path: 'plans',
        name: 'plans',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/Plans.vue'),
      },
      {
        path: 'billing',
        name: 'billing',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/Billing.vue'),
      },
      {
        path: 'services/:type(shop|courier)',
        name: 'services',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/Services.vue'),
      },
      {
        path: 'shipmentlogic',
        name: 'shipmentlogic',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/ShipmentLogic.vue'),
      },
      {
        path: 'gdpr',
        name: 'gdpr',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/GDPR.vue'),
      },
      // {
      //     path: 'invoices',
      //     name: 'invoice-list',
      //     component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/InvoiceList.vue'),
      // },
      {
        path: 'invoices/:invoiceId',
        name: 'invoice-single',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/InvoiceSingle.vue'),
      },
      {
        path: 'tickets',
        name: 'ticket-list',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/TicketList.vue'),
      },
      {
        path: 'tickets/:ticketId',
        name: 'ticket-single',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/TicketSingle.vue'),
      },
      {
        path: 'api-keys',
        name: 'api-keys',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/ApiKeys.vue'),
      },
    ],
  },
  {
    path: '/import',
    name: 'import',
    component: () => import(/* webpackChunkName: "import" */ '../views/Import.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/scan',
    name: 'scan',
    component: () => import(/* webpackChunkName: "scan" */ '../views/Scan.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/onboard',
    name: 'onboard',
    props: route => ({ serviceName: route.query.service }),
    component: Onboard,
  },
  {
    path: '/onboard/first-login',
    name: 'first-login',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/FirstLogin.vue'),
  },
  {
    path: '/authentication',
    name: 'loading',
    component: Loading,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

// Monkeypatch some helper functions for easier history navigation
router._previousRoute = null;

router.beforeEach((to, from, next) => {
  if (CONFIG_APP_IS_DOWN) {
    console.log('App is down, redirecting to down page');
    if (to.name !== 'down') {
      next({ name: 'down' });
    } else {
      next();
    }
  } else {
    if (to.name === 'down') {
      next({ name: 'home' });
    } else {
      router._previousRoute = from;
      next();
    }
  }
});

router.afterEach(() => {
  window.Intercom('update');
});

router.getPreviousRoute = function () {
  /**
   * Returns previous route, unless the page is freshly loaded (because of a
   * reload or explicit navigation)
   */
  if (VueRouter.START_LOCATION === this._previousRoute || this._previousRoute.name === 'loading') return null;
  return this._previousRoute;
};

router.backOrDefault = function (route) {
  /**
   * Navigate back in history, unless that might lead to an external page. In
   * that case, push a to the default route.
   *
   * TODO: replace may be more semantically appropriate than push, which makes
   * more sense from the usability perspective?
   */
  if (this.getPreviousRoute()) this.go(-1);
  else this.push(route);
};

export default router;
