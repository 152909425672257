<template>
  <main class="home">
    <div class="tw-container tw-px-0 md:tw-px-8 tw-pt-8 tw-max-w-none xl:tw-max-w-screen-3xl">
      <notifications />
      <div class="tw-flex tw-px-8 md:tw-px-0 tw-justify-end tw-items-center tw-mb-8">
        <b-field v-if="user && user.organisation" class="has-dropdown">
          <p class="control">
            <b-button tag="router-link" :to="{ name: 'order_create' }">
              <div style="align-items: center; display: flex; gap: 8px">
                <font-awesome-icon fixed-width icon="plus" />
                Nieuwe zending
              </div>
            </b-button>
          </p>
          <p class="control">
            <b-dropdown position="is-bottom-left">
              <template #trigger>
                <b-button class="tw-p-3">
                  <font-awesome-icon fixed-width icon="chevron-down" />
                </b-button>
              </template>

              <b-dropdown-item aria-role="listitem" has-link>
                <router-link :to="{ name: 'import' }">
                  <b-icon icon="software-upload" />
                  Upload CSV
                </router-link>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="isTrial"
                aria-role="listitem"
                :disabled="isUpdatingTestOrders"
                :loading="isUpdatingTestOrders"
                @click="generateTestOrders()"
              >
                <b-icon icon="file-add" />
                Genereer test orders
              </b-dropdown-item>
            </b-dropdown>
          </p>
        </b-field>
      </div>
      <b-tabs v-model="activeTab" type="is-boxed" @input="updateRoute">
        <b-tab-item label="Bestellingen">
          <order-tab
            :order-filter="{ has_shipments: false, status: ['ready', 'incomplete', 'warning', 'error'] }"
            view-name="orderTab"
          />
        </b-tab-item>
        <b-tab-item label="Labels">
          <label-tab
            :default-sort="['created_at', 'desc']"
            :label-actions="[LABEL_ACTIONS.PRINT, LABEL_ACTIONS.DEACTIVATE]"
            :label-filter="{ is_returned: false, status: ['ready', 'printed', 'error'] }"
            label-timestamp="created_at"
            view-name="untrackedTab"
          />
        </b-tab-item>
        <b-tab-item label="Verzonden">
          <label-tab
            item-name="zending"
            item-name-plural="zendingen"
            :label-actions="[LABEL_ACTIONS.SET_DELIVERED, LABEL_ACTIONS.CREATE_TICKET, LABEL_ACTIONS.PRINT]"
            :label-filter="{
              is_returned: false,
              status: ['shipped', 'delayed'],
            }"
            view-name="trackedTab"
          />
        </b-tab-item>
        <b-tab-item label="Bezorgd">
          <label-tab
            item-name="zending"
            item-name-plural="zendingen"
            :label-actions="[LABEL_ACTIONS.CREATE_TICKET, LABEL_ACTIONS.PRINT]"
            :label-filter="{ is_returned: false, status: ['completed'] }"
            view-name="deliveredTab"
          />
        </b-tab-item>
        <b-tab-item label="Retouren">
          <return-tab :label-filter="{ is_returned: true }" label-timestamp="created_at" view-name="returnTab" />
        </b-tab-item>
        <b-tab-item label="Overige bestellingen">
          <order-tab :controls="false" :order-filter="{ status: ['pending', 'other', 'ignored'] }" view-name="otherTab" />
        </b-tab-item>
      </b-tabs>
    </div>
  </main>
</template>

<script>
  import { mapState } from 'vuex';
  import Notifications from '@/components/Notifications';
  import LabelTab from '@/components/tabs/LabelTab';
  import OrderTab from '@/components/tabs/OrderTab';
  import ReturnTab from '@/components/tabs/ReturnTab';
  import { LABEL_ACTIONS } from '@/constants/COMMON';
  import { CONFIG_POLL_INTERVAL_IN_SECONDS } from '@/config';

  const AVAILABLE_TABS = ['orders', 'labels', 'tracking', 'delivered', 'return', 'other'];
  const TAB_META = {
    orders: {
      resource: 'order',
      view: 'orderTab',
    },
    problematic: {
      resource: 'order',
      view: 'problematicTab',
    },
    labels: {
      resource: 'label',
      view: 'untrackedTab',
    },
    tracking: {
      resource: 'label',
      view: 'trackedTab',
    },
    delivered: {
      resource: 'label',
      view: 'deliveredTab',
    },
    delayed: {
      resource: 'label',
      view: 'delayedTab',
    },
    return: {
      resource: 'label',
      view: 'returnTab',
    },
    other: {
      resource: 'order',
      view: 'otherTab',
    },
  };

  export default {
    components: { LabelTab, OrderTab, Notifications, ReturnTab },
    props: {
      activeTabName: {
        type: String,
        default: 'orders',
      },
    },
    data() {
      return {
        activeTab: Math.max(AVAILABLE_TABS.indexOf(this.activeTabName), 0),
        updateIntervalId: null,
        isUpdatingTestOrders: false,
        LABEL_ACTIONS,
      };
    },
    computed: {
      ...mapState({
        user: state => state.account.user,
        organisation: state => state.account.organisation,
      }),
      isTrial() {
        return this.organisation?.plan?.capabilities?.trial ? this.organisation.plan.capabilities.trial : false;
      },
    },
    watch: {},
    methods: {
      updateData() {
        this.$store.dispatch('order/reloadViews', { silent: true });
        this.$store.dispatch('label/reloadViews', { silent: true });
      },
      initBackgroundUpdate() {
        this.updateIntervalId = setInterval(() => this.updateData(), CONFIG_POLL_INTERVAL_IN_SECONDS * 1000);
      },
      async generateTestOrders() {
        this.isUpdatingTestOrders = true;
        await this.$store.dispatch('order/generateTestOrders');
        this.isUpdatingTestOrders = false;
      },
      updateRoute(newTabIndex) {
        if (AVAILABLE_TABS[newTabIndex]) {
          this.$router.push({ name: 'dashboard', params: { activeTabName: AVAILABLE_TABS[newTabIndex] } });
        }
      },
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.initBackgroundUpdate();
      });
    },
    beforeRouteUpdate(to, from, next) {
      if (to.name === 'dashboard' && to.params?.activeTabName in TAB_META) {
        const tab = TAB_META[to.params.activeTabName];
        this.$store.dispatch(`${tab.resource}/reloadView`, { view: tab.view, silent: true });
        this.activeTab = AVAILABLE_TABS.indexOf(to.params.activeTabName);
      }
      next();
    },
    beforeRouteLeave(to, from, next) {
      clearInterval(this.updateIntervalId);
      next();
    },
  };
</script>
