export const LABEL_ACTIONS = {
  DEACTIVATE: 'deactivate',
  PRINT: 'print',
  SET_DELIVERED: 'set_delivered',
  CREATE_TICKET: 'create_ticket',
};

export const labelActions = Object.values(LABEL_ACTIONS);

/**
 * Interne representatie	Label status	Fase	Dashboard	Publiek	Uitleg
 * ready	ready	0	Niet geprint	Bestelling nog niet gereed voor verzending	Zie label status.
 * printed	printed	0	Geprint	Klaar voor afhaling door vervoerder	Zie label status.
 * shipped	shipped	1	Onderweg	Ontvangen door vervoerder	Label is gescand door de vervoerder.
 * at_depot	shipped	1	In sorteercentrum	In sorteercentrum van vervoerder	Zending is in het sorteercentrum.
 * at_destination_country	shipped	1	Aangekomen in land van bestemming	Aangekomen in land van bestemming	Exportzending is aangekomen in land van bestemming.
 * customs_cleared	shipped	1	Inklaring voltooid	Inklaring voltooid	Exportzending is ingeklaard door douane.
 * at_destination_country_depot	shipped	1	In sorteercentrum in land van bestemming	In sorteercentrum in land van bestemming	Exportzending is in het sorteercentrum in land van bestemming.
 * out_for_delivery	shipped	2	In uitlevering	Bezorger is onderweg	Bezorger is onderweg naar de ontvanger.
 * at_service_point	shipped	3	Aangekomen op servicepunt	Aangekomen op servicepunt	Zending ligt bij het servicepunt.
 * delivered	completed	3	Bezorgd	Bezorgd door de vervoerder	Zending is bezorgd bij de ontvanger.
 * delivered_at_neighbors	completed	3	Bezorgd bij buren	Bezorgd bij buren	Zending is bezorgd bij buren van de ontvanger.
 * delivered_at_sender	completed	1	Bezorgd bij afzender	Bezorgd bij afzender	Zending is retour gekomen en bezorgd bij de afzender.
 * collected_at_service_point	completed	3	Afgehaald op servicepunt	Afgehaald op servicepunt	Zending is door de ontvanger afgehaald bij het servicepunt.
 * sorting_delayed	delayed	1	Vertraging	In sorteercentrum van vervoerder	Zending heeft vertraging opgelopen in het sorteercentrum.
 * action_required_recipient	delayed	1	Actie vereist van ontvanger	Actie vereist van ontvanger	Zending zit vast, de ontvanger moet iets doen om verder te komen.
 * action_required_sender	delayed	1	Actie vereist van afzender	Actie vereist van afzender	Zending zit vast, de zender moet iets doen om verder te komen.
 * delivery_delayed	delayed	1	Vertraging in de bezorging	Bezorger is onderweg	Zending heeft vertraging opgelopen tijdens de bezorging.
 * delivery_failed	delayed	1	Bezorging gemist	Bezorging gemist, nieuwe bezorgpoging verwacht	Bezorgpoging mislukt omdat de ontvanger niet aanwezig was.
 * sorting_error	error	1	Probleem tijdens sorteren	In sorteercentrum van vervoerder	Zending kan niet worden gesorteerd en is afgekeurd.
 * returned	error	1	Retour naar afzender	Pakket gaat retour naar afzender	Pakket gaat retour naar afzender om onbekende reden.
 * returned_from_service_point	error	1	Retour van servicepunt	Pakket niet opgehaald bij servicepunt, gaat retour naar afzender	De ontvanger heeft de zending niet op tijd opgehaald bij het servicepunt en dus gaat de zending retour.
 */
export const TRACKING_STATUS_MAPPING = {
  /** Hardcoded statuses */
  order_created: {
    tag: 'Bestelling geplaatst',
    tooltip: 'Bestelling is geplaatst',
    label_status: 'ready',
    icon: 'box-open',
  },

  ticket_created: {
    tag: 'Ticket aangemaakt',
    tooltip: 'Ticket is aangemaakt',
    label_status: 'ready',
    icon: 'ticket',
  },

  /** this status is added if the ready and printed events are within 1 minute of each other */
  ready_printed: {
    tag: 'Label aangemaakt',
    tooltip: 'Label is aangemaakt en geprint, bestelling gereed voor verzending',
    label_status: 'ready',
    icon: 'file',
  },

  /** Server Tracking Statuses */
  action_required_recipient: {
    tag: 'Actie vereist van ontvanger',
    tooltip: 'Actie vereist van ontvanger',
    label_status: 'delayed',
  },
  action_required_sender: {
    tag: 'Actie vereist van afzender',
    tooltip: 'Actie vereist van afzender',
    label_status: 'delayed',
  },
  at_depot: {
    tag: 'In sorteercentrum',
    tooltip: 'In sorteercentrum van vervoerder',
    label_status: 'shipped',
    icon: 'truck-fast',
  },
  at_destination_country: {
    tag: 'Aangekomen in land van bestemming',
    tooltip: 'Aangekomen in land van bestemming',
    label_status: 'shipped',
    icon: 'truck-fast',
  },
  at_destination_country_depot: {
    tag: 'In sorteercentrum in land van bestemming',
    tooltip: 'In sorteercentrum in land van bestemming',
    label_status: 'shipped',
    icon: 'truck-fast',
  },
  at_service_point: {
    tag: 'Aangekomen op servicepunt',
    tooltip: 'Aangekomen op servicepunt',
    label_status: 'shipped',
    icon: 'truck-fast',
  },
  collected_at_service_point: {
    tag: 'Afgehaald op servicepunt',
    tooltip: 'Afgehaald op servicepunt',
    label_status: 'completed',
  },
  customs_cleared: {
    tag: 'Inklaring voltooid',
    tooltip: 'Inklaring voltooid',
    label_status: 'shipped',
    icon: 'truck-fast',
  },
  delivered: {
    tag: 'Bezorgd',
    tooltip: 'Bezorgd door de vervoerder',
    label_status: 'completed',
    icon: 'check-circle',
  },
  delivered_at_neighbors: {
    tag: 'Bezorgd bij buren',
    tooltip: 'Bezorgd bij buren',
    label_status: 'completed',
  },
  delivered_at_sender: {
    tag: 'Bezorgd bij afzender',
    tooltip: 'Bezorgd bij afzender',
    label_status: 'completed',
  },
  delivery_delayed: {
    tag: 'Vertraging in de bezorging',
    tooltip: 'Bezorger is onderweg',
    label_status: 'delayed',
  },
  delivery_failed: {
    tag: 'Bezorging gemist',
    tooltip: 'Bezorging gemist, nieuwe bezorgpoging verwacht',
    label_status: 'delayed',
  },
  out_for_delivery: {
    tag: 'In uitlevering',
    tooltip: 'Bezorger is onderweg',
    label_status: 'shipped',
    icon: 'truck-fast',
  },
  printed: {
    tag: 'Label geprint',
    tooltip: 'Klaar voor afhaling door vervoerder',
    label_status: 'printed',
    icon: 'print',
  },
  ready: {
    tag: 'Label aangemaakt', //Niet geprint
    tooltip: 'Label is aangemaakt maar nog niet geprint, bestelling nog niet gereed voor verzending',
    label_status: 'ready',
    icon: 'file',
  },
  returned: {
    tag: 'Retour naar afzender',
    tooltip: 'Pakket gaat retour naar afzender',
    label_status: 'error',
  },
  returned_from_service_point: {
    tag: 'Retour van servicepunt',
    tooltip: 'Pakket niet opgehaald bij servicepunt, gaat retour naar afzender',
    label_status: 'error',
  },
  shipped: {
    tag: 'Onderweg',
    tooltip: 'Ontvangen door vervoerder',
    label_status: 'shipped',
    icon: 'truck-fast',
  },
  sorting_delayed: {
    tag: 'Vertraging',
    tooltip: 'In sorteercentrum van vervoerder',
    label_status: 'delayed',
  },
  sorting_error: {
    tag: 'Probleem tijdens sorteren',
    tooltip: 'In sorteercentrum van vervoerder',
    label_status: 'error',
  },
};

export const STATUS_MAPPING = {
  label: {
    ready: {
      tag: 'Label aangemaakt',
      tooltip: 'Label gemaakt, nog niet afgedrukt. Multicolli: alle labels zijn gemaakt.',
    },
    printed: {
      tag: 'Label aangemaakt',
      tooltip: 'Label afgedrukt, nog niet gescand. Multicolli: alle labels zijn afgedrukt.',
    },
    shipped: {
      tag: 'Onderweg',
      tooltip: 'Zending onderweg. Multicolli: minstens één pakket is onderweg.',
    },
    delayed: {
      tag: 'Vertraagd',
      tooltip: 'Zending vertraagd. Multicolli: minstens één pakket is vertraagd.',
    },
    error: {
      tag: 'Probleem',
      tooltip: 'Zending heeft een probleem. Multicolli: minstens één pakket heeft een probleem.',
    },
    completed: {
      tag: 'Bezorgd',
      tooltip: 'Zending bezorgd. Multicolli: alle pakketten zijn bezorgd.',
    },
  },

  order: {
    pending: {
      tag: 'In afwachting',
      tooltip: 'Order wacht op verwerking.',
    },
    incomplete: {
      tag: 'Niet volledig',
      tooltip: 'Niet genoeg informatie om te valideren.',
    },
    error: {
      tag: 'Validatie fout',
      tooltip: 'Validatie niet succesvol.',
    },
    warning: {
      tag: 'Controleer gegevens',
      tooltip: 'Mogelijke problemen bij label aanmaken.',
    },
    ready: {
      tag: 'Klaar voor verwerken',
      tooltip: 'Order klaar voor label aanmaken.',
    },
    completed: {
      tag: 'Afgerond',
      tooltip: 'Label aangemaakt, geen nieuwe status mogelijk.',
    },
  },
};

export const STATUS_INFO_MAPPING = {
  missing_address_1: 'Adres is vereist',
  missing_housenumber: 'Huisnummer is vereist',
  missing_zipcode: 'Postcode is vereist',
  missing_city: 'Woontplaats is vereist',
  missing_country: 'Land is vereist',
  missing_email: 'E-mailadres is vereist',
  missing_street: 'Straatnaam is vereist',
  missing_currency: 'Valuta is vereist voor exportzendingen',
  missing_shipment_weight: 'Gewicht is vereist',
  missing_phone: 'Telefoonnummer order of organisatie ontbreekt',
  missing_order_number: 'Ordernummer is vereist',

  limit_phone: 'Telefoonnummer is te lang',
  limit_name: 'Naam is te lang of te kort',
  limit_order_number: 'Ordernummer is te lang',
  limit_address_1: 'Adresregel 1 is te lang',
  limit_housenumber: 'Huisnummer is te lang',
  limit_company_name: 'Bedrijfsnaam is te lang',
  limit_zipcode: 'Postcode is te lang',
  limit_city: 'Woontplaats is te lang',
  limit_country: 'Land is te lang',
  limit_email: 'E-mailadres is te lang',
  limit_org_email: 'E-mailadres organisatie is te lang',
  limit_street: 'Straatnaam is te lang',
  limit_shipment_weight: 'Gewicht van uw order is te zwaar: dit mag maximaal 31.5 kilo zijn',
  limit_number_suffix: 'Achtervoegsel huisnummer of adresregel 2 is te lang.',

  inactive_shipment_type: 'Verzendmethode niet beschikbaar',
  inactive_service_id: 'Verzendmethode niet beschikbaar',
  form_validation_courier_fields: 'Verzendopties niet volledig', // Don't split this for now, since we don't have full control (from frontend perspective) over these messages
  blocked_product_country: 'Land wordt niet ondersteund door Innosend',

  // order item fields
  no_order_items_order_items: 'ORDER ITEM: Geef tenminste één order item mee',
  validation_hs_code: 'Onjuiste formaat hs-code',
  missing_sku: 'ORDER ITEM: SKU-nummer is vereist',
  missing_hs_code: 'ORDER ITEM: HS-code is vereist',
  limit_hs_code: 'ORDER ITEM: lengte hs-code is te lang',
  missing_description: 'ORDER ITEM: Omschrijving is vereist',
  missing_weight: 'ORDER ITEM: Gewicht per oder item is vereist',
  missing_unit_price_inc_btw: 'ORDER ITEM: Prijs per order item is vereist',
  missing_product_country: 'ORDER ITEM: Land van herkomst is vereist',
  missing_total_weight: 'ORDER ITEM: Controleer gewicht en aantal',
  missing_total_price: 'ORDER ITEM: Controleer prijs en aantal',

  // company errors
  missing_company_characteristics: 'Ga naar instellingen om de bedrijfskenmerken in te vullen',
  missing_eori: 'Ga naar instellingen om EORI-nummer in te stellen',
  missing_eori_gb: 'Ga naar instellingen om EORI-nummer GB in te stellen',
  missing_vatin: 'Stel BTW-nummer in bij instellingen',
  missing_company_phone: 'Stel landelijke telefoonnummer in bij instellingen',
  missing_hmrc: 'Ga naar instellingen om HMRC in te vullen',
  limit_company_zipcode: 'Lengte postcode organisatie is te groot',

  validation_hmrc: 'Onjuiste formaat hmrc-code',
  validation_zipcode: 'Onjuiste formaat postcode',
  validation_vatin: 'Onjuiste formaat BTW-nummer',
  validation_org_phone: 'Controleer het nationaal telefoonnummer van je organisatie',
  validation_order_phone: 'Controleer het nationaal telefoonnummer van je order',
  validation_phone: 'Controleer het nationaal telefoonnummer van je order en organisatie',
  validation_eori: 'Onjuiste formaat EORI-nummer',

  // courier_fields
  not_allowed_courier_fields: 'Verzendopties zijn niet toegestaan bij gekozen verzendmethode',
  validation_courier_fields: 'Eén of meerdere verzendopties zijn niet toegestaan bij het ingevoerde adres',

  // other errors
  missing_weight_digitale_postzegel: 'Digitale postzegel heeft een gewicht nodig',
  validation_is_export:
    'Geselecteerde vervoerder ondersteund geen zendingen buiten de EU. Gebruik hiervoor DPD of UPS.',

  // warnings
  out_of_range_zipcode: 'Postcode buiten levergebied',
  no_validation_zipcode: 'Innosend is niet in staat om de postcode te valideren. Dit doet de vervoerder.',
};
