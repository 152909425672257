import Vue from 'vue';
import Buefy from 'buefy';
import * as Sentry from '@sentry/vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import filters from '@/utils/filters';
import TemplateWith from '@/components/TemplateWith';
import { initKeycloak } from '@/utils/keycloak';
import { reportError } from '@/utils/errors';
import i18n from '@/i18n';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as FONT_AWESOME_ICONS from '@/constants/FONT_AWESOME_ICONS';
import { CONFIG_ENV } from '@/config';

/** @type{import('buefy/types/components').BuefyConfig} */
const buefyConfig = {
  defaultIconPack: 'gg',
  customIconPacks: {
    gg: {
      sizes: {
        'default': 'is-size-5',
        'is-small': '',
        'is-medium': 'is-size-3',
        'is-large': 'is-size-1',
      },
      iconPrefix: 'gg-',
      internalIcons: {
        'check': 'check',
        'information': 'info',
        'check-circle': 'check-o',
        'alert': 'danger',
        'alert-circle': 'danger',
        'arrow-up': 'arrow-up',
        'chevron-right': 'chevron-right',
        'chevron-left': 'chevron-left',
        'chevron-down': 'arrow-down',
        'eye': 'eye',
        'eye-off': 'eye',
        'menu-down': 'chevron-down',
        'menu-up': 'chevron-up',
        'close-circle': 'close-o',
      },
    },
  },
};

function initializeSentry() {
  Sentry.init({
    Vue,
    environment: CONFIG_ENV,
    dsn: 'https://2c7a0488aba747a7aaa0f8fb1ad97093@o1057177.ingest.us.sentry.io/6043850',
    // tunnel: 'https://api.innosend.eu/bugs/',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.feedbackIntegration({
        autoInject: false,
      }),
    ],
    tracesSampleRate: 0.01,
  });
}

function setupGlobalErrorHandlers() {
  window.addEventListener('unhandledrejection', function (event) {
    if (navigator.onLine) reportError(event.reason);
    event.preventDefault();
  });

  Vue.config.errorHandler = function (error) {
    if (navigator.onLine) reportError(error);
  };
}

function initializeVueApp() {
  Vue.config.productionTip = false;

  Vue.use(Buefy, buefyConfig);
  Vue.use(filters);
  Vue.component('v-with', TemplateWith);

  library.add(...Object.values(FONT_AWESOME_ICONS));
  Vue.component('font-awesome-icon', FontAwesomeIcon);

  initKeycloak().then(() => {
    window.innosendApp = new Vue({
      router,
      store,
      i18n,
      render: h => h(App),
    }).$mount('#app');
  });
}

initializeSentry();
initializeVueApp();
setupGlobalErrorHandlers();
